
import { getOrderGroup } from '@/api/orderGroup';
import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import { getCancelOrder } from '@/api/order';
import { JoinModule } from '@/store/join';
import { Form } from 'element-ui';
import { ElForm } from 'element-ui/types/form';

@Component({
  name: 'DeliveryDetail',
})

export default class extends Vue {
  private groupIdx = Number(this.$route.params.orderGroupId);

  private detail: any = {
    week: {},
    reviewStatus: false,
    reviewExpiredStatus: true,
    lastPickupStatus: false,
    productNum: 0,
    shop: {},
    station: {},
  };

  private allRefundCk = false;

  private selectedWeek: null | number = null;

  private refundVisible = false;

  private completeVisible = false;

  private allRefundVisible = false;

  private previousItem: any = '';

  private previousWeek: any = '';

  private curDate: any = moment().format('YYYY-MM-DD');

  private form = {
    cancelReason: '',
    cancelReasonType: '',
  }

  private rules = {
    cancelReason: [
      { required: true, message: '상세사유를 입력해주세요.', trigger: 'blur' },
    ],
    cancelReasonType: [
      { required: true, message: '취소사유를 선택해주세요.', trigger: 'blur' },
    ],
  }

  async mounted() {
    await this.getOrderGroup();
  }

  private computeRefundDate(startDate: any, dayNum: any) {
    const res: any = moment(startDate).add(dayNum, 'day').add(-3, 'day').format('YYYY-MM-DD');
    const res2: any = moment(res).diff(this.curDate, 'days');
    return res2;
  }

  private async getOrderGroup() {
    await getOrderGroup(this.groupIdx).then((res) => {
      this.detail = res.data;
    }).then(() => {
      let startDate = '';
      let dayNum = '';
      if (this.detail.week[1]) {
        startDate = this.detail.week[1].startDate;
        dayNum = this.detail.week[1].products[0].dayNum;
      } else if (this.detail.week[2]) {
        startDate = this.detail.week[2].startDate;
        dayNum = this.detail.week[2].products[0].dayNum;
      } else if (this.detail.week[3]) {
        startDate = this.detail.week[3].startDate;
        dayNum = this.detail.week[3].products[0].dayNum;
      } else if (this.detail.week[4]) {
        startDate = this.detail.week[4].startDate;
        dayNum = this.detail.week[4].products[0].dayNum;
      }
      this.allRefundCk = this.computeRefundDate(startDate, dayNum) >= 0;
    });
  }

  private writableReview() {
    return this.detail.orderType === 'PICKUP' && this.detail.lastPickupStatus && !this.detail.reviewExpiredStatus && !this.detail.reviewStatus;
  }

  private getTotalWeek() {
    let totalWeek: any = 0;
    const weeks = Object.keys(this.detail.week);
    if (weeks.length > 0) totalWeek = weeks.length;
    return totalWeek;
  }

  private getTotalMealDate() {
    const days: any = [];
    const weeks: any = Object.keys(this.detail.week);
    for (let i = 0; i < weeks.length; i += 1) {
      this.detail.week[weeks[i]].products.forEach((product: any) => {
        const dayKey = `${product.weekNum}-${product.dayNum}`;
        if (days.indexOf(dayKey) < 0) days.push(dayKey);
      });
    }
    return days.length;
  }

  private handleRefundVisible(week: number) {
    this.refundVisible = !this.refundVisible;
    if (this.refundVisible) this.selectedWeek = week;
    else this.selectedWeek = null;
  }

  private shouldDisplayItem(item: any, week: any) {
    if (this.previousItem === item && this.previousWeek === week) {
      this.previousItem = item;
      this.previousWeek = week;
      return false;
    }
    this.previousItem = item;
    this.previousWeek = week;
    return true;
  }

  private handleRefund() {
    getCancelOrder({
      orderId: this.groupIdx,
      week: this.selectedWeek,
    }).then((res) => {
      this.completeVisible = true;
      this.refundVisible = false;
      this.getOrderGroup();
    });
  }

  private handleCompleteRefundVisible() {
    this.completeVisible = false;
    this.$router.push({ name: 'Delivery' });
  }

  // 전체 취소
  private handleAllRefundVisible() {
    this.allRefundVisible = true;
    (window as any).scrollTo(0, 0);
  }

  private handleCloseAllRefundVisible() {
    this.allRefundVisible = false;
  }

  private handleCompleteAllRefund() {
    (this.$refs.form as ElForm).validate((valid: boolean) => {
      if (valid) {
        getCancelOrder({
          orderId: this.groupIdx,
          week: 1,
          allStatus: true,
          cancelReasonType: this.form.cancelReasonType,
          cancelReason: this.form.cancelReason,
        }).then(() => {
          this.allRefundVisible = false;
          this.completeVisible = true;
          this.getOrderGroup();
        }).catch(() => {
          this.$message.error('전체 취소를 실패했습니다.');
        });
      }
    });
  }

  private categoryEvent() {
    if (this.form.cancelReasonType === '기타') {
      this.categoryFlag = true;
    } else {
      this.categoryFlag = false;
    }
  }

  private categoryFlag = false;
}
